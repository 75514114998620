import React from "react";
import { FormUi } from "../../../../ui/FormUi";
import {
  DateFormats,
  FiltersSearch_Types,
  TipoButtonConstants,
} from "../../../../../types/typesConstants";
import { Col, DatePicker, Form, Input, Row } from "antd";
import { SelectUi } from "../../../../ui/SelectUi";
import { ProveedorSelect } from "../../../reusable/ProveedorSelect";
import { ButtonUi } from "../../../../ui/ButtonUi";
import {
  programacionTejidoStartLoading,
  setFilterSearchProgramacionTejido,
} from "../../../../../actions/produccion";
import { useDispatch } from "react-redux";
import { parseDateFormatBackEnd } from "../../../../../helpers/utils";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const initialValues = {
  filter_type: null,
  fechaemision: [dayjs(), dayjs()],
  codigo: null,
  proveedor: null,
};
const options = [
  {
    value: FiltersSearch_Types.CODIGO,
    label: "Código",
  },
  {
    value: FiltersSearch_Types.FECHAEMISION,
    label: "Fecha emisión",
  },
  {
    value: FiltersSearch_Types.PROVEEDOR,
    label: "Proveedor",
  },
];
export const ProgramacionTejidoSearchTable = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const filter_type = Form.useWatch("filter_type", form);
  const handleSubmit = (values) => {
    let query = null;
    if (filter_type === FiltersSearch_Types.CODIGO) query = values?.codigo;
    else if (filter_type === FiltersSearch_Types.FECHAEMISION) {
      query = `${parseDateFormatBackEnd(
        values?.fechaemision?.[0]
      )}|${parseDateFormatBackEnd(values?.fechaemision?.[1])}`;
    } else if (filter_type === FiltersSearch_Types.PROVEEDOR)
      query = values?.proveedor;
    dispatch(programacionTejidoStartLoading(values?.filter_type, query));
  };

  const OnChangeFilterType = () => {
    if (filter_type === FiltersSearch_Types.CODIGO)
      form.setFieldsValue({ fechaemision: null, proveedor: null });
    else if (filter_type === FiltersSearch_Types.FECHAEMISION)
      form.setFieldsValue({ codigo: null, proveedor: null });
    else if (filter_type === FiltersSearch_Types.PROVEEDOR)
      form.setFieldsValue({ fechaemision: null, proveedor: null });
  };
  return (
    <FormUi
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      initialValues={initialValues}
      handleSubmit={handleSubmit}
    >
      <Row>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Form.Item name="filter_type" label="Filtrar por">
            <SelectUi
              allowClear
              placeholder="[-TODOS-]"
              data={options}
              onChange={OnChangeFilterType}
            />
          </Form.Item>
        </Col>
        {filter_type === FiltersSearch_Types.CODIGO && (
          <Col xs={24} sm={24} md={8} lg={4} xl={4} offset={1}>
            <Form.Item
              name="codigo"
              rules={[
                {
                  required: filter_type === FiltersSearch_Types.CODIGO,
                  message: "Ingrese código",
                },
              ]}
            >
              <Input placeholder="Ingrese un código" />
            </Form.Item>
          </Col>
        )}
        {filter_type === FiltersSearch_Types.FECHAEMISION && (
          <Col xs={24} sm={24} md={8} lg={4} xl={4} offset={1}>
            <Form.Item
              name="fechaemision"
              rules={[
                {
                  required: filter_type === FiltersSearch_Types.FECHAEMISION,
                  message: "Ingrese fecha emisión",
                },
              ]}
            >
              <RangePicker format={DateFormats.frontend} />
            </Form.Item>
          </Col>
        )}
        {filter_type === FiltersSearch_Types.PROVEEDOR && (
          <Col xs={24} sm={24} md={8} lg={6} xl={6} offset={1}>
            <Form.Item
              name="proveedor"
              rules={[
                {
                  required: filter_type === FiltersSearch_Types.PROVEEDOR,
                  message: "Seleccione proveedor",
                },
              ]}
            >
              <ProveedorSelect placeholder="Seleccione proveedor" />
            </Form.Item>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={4} xl={4} offset={1}>
          <ButtonUi typeUi={TipoButtonConstants.Filtrar} htmlType="submit" />
        </Col>
      </Row>
    </FormUi>
  );
};
