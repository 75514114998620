import { Form } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormUi } from "../../../ui/FormUi";
import dayjs from "dayjs";
import { ResumenProduccionForm } from "./ResumenProduccionForm";
import { DateFormats } from "../../../../types/typesConstants";
import { parseNumeric } from "../../../../helpers/utils";
import {
  resumenProduccionClearActive,
  resumenProduccionStartAdd,
  resumenProduccionStartUpdate,
} from "../../../../actions/produccion";
const initialValues = (data = {}) => ({
  id: data?.id || null,
  descripcion: data?.descripcion || null,
  fechahorainicio: dayjs(data?.fechahorainicio) || dayjs(),
  fechahorafin: dayjs(data?.fechahorafin) || null,
  costototalmanoobra: parseNumeric(data?.costototalmanoobra) || 0,
  costototalmateriaprima: parseNumeric(data?.costototalmateriaprima) || 0,
  costototalindirecto: parseNumeric(data?.costototalindirecto) || 0,
  costototalproduccion: parseNumeric(data?.costototalproduccion) || 0,
  detalleresumenproduccion: data?.detalleresumenproduccion || [],
  insumosresumenproduccion: data?.insumosresumenproduccion || null,
  estado: data?.estado || null,
});
const onlyInLeft = (left, right, compareFunction) =>
  left.filter(
    (leftValue) =>
      !right?.some((rightValue) => compareFunction(leftValue, rightValue))
  );

export const ResumenProduccionScreen = ({ handleCloseForm }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { activeResumenProduccion, activeDetalleResumenProduccion } =
    useSelector((state) => state.produccion);
  const handleSubmit = (values) => {
    //TODO: INICIO - Actualizar costos de producción antes de guardar
    var _cantidadTotal = values?.detalleresumenproduccion.reduce(
      (accum, item) => accum + parseFloat(item?.cantidad),
      1
    );
    const _detalleresumenproduccion = values?.detalleresumenproduccion?.map(
      (rowData) => {
        // const _cantidad = rowData?.cantidad;
        const _costounitariomanoobrasoles = parseNumeric(
          rowData.costounitariomanoobrasoles
        );
        const _costounitariomateriaprimasoles = parseNumeric(
          parseFloat(values?.costototalmateriaprima) / _cantidadTotal
        );
        const _costounitarioindirectosoles = parseNumeric(
          parseFloat(values?.costototalindirecto) / _cantidadTotal
        );
        const _costounitarioproduccionsoles = parseNumeric(
          _costounitariomanoobrasoles +
            _costounitariomateriaprimasoles +
            _costounitarioindirectosoles
        );
        rowData = {
          ...rowData,
          costounitariomateriaprimasoles: _costounitariomateriaprimasoles,
          costounitarioindirectosoles: _costounitarioindirectosoles,
          costounitarioproduccionsoles: _costounitarioproduccionsoles,
        };
        return rowData;
      }
    );

    //TODO: FIN - Actualizar costos de producción antes de guardar
    values = {
      ...values,
      fechahorainicio: dayjs(values?.fechahorainicio).format(
        DateFormats.DatetimeBackEnd
      ),
      fechahorafin: dayjs(values?.fechahorafin).format(
        DateFormats.DatetimeBackEnd
      ),
      detalleresumenproduccion: _detalleresumenproduccion,
    };
    if (activeResumenProduccion) dispatch(resumenProduccionStartUpdate(values));
    else dispatch(resumenProduccionStartAdd(values));

    handleCloseForm();
    dispatch(resumenProduccionClearActive());
  };
  return (
    <Form.Provider
      onFormFinish={(name, { values, forms }) => {
        const { FormResumenProduccion } = forms;
        const detalleresumenproduccion =
          FormResumenProduccion.getFieldValue("detalleresumenproduccion") || [];
        const insumosresumenproduccion =
          FormResumenProduccion.getFieldValue("insumosresumenproduccion") || [];
        if (name === "FormDetalleProduccion") {
          if (activeDetalleResumenProduccion)
            FormResumenProduccion.setFieldsValue({
              detalleresumenproduccion: detalleresumenproduccion.map((e) =>
                e.id === values.id ? values : e
              ),
            });
          else
            FormResumenProduccion.setFieldsValue({
              detalleresumenproduccion: [...detalleresumenproduccion, values],
            });
        }

        if (name === "FormDetalleComprobanteMovimiento") {
          if (values?.tipoinsumo === "MAPRIMA") {
            //AGREGAR MATERIA PRIMA
            const newInsumos = values?.insumosresumenproduccion?.map((row) => ({
              ...row,
              detallecomprobantemovimiento: row?.id,
              descripcion: row?.item_nombre,
              costounitario: row?.costo,
            }));
            const isSame = (a, b) => a.id === b.id;
            const results = onlyInLeft(
              newInsumos,
              insumosresumenproduccion,
              isSame
            );
            FormResumenProduccion.setFieldValue("insumosresumenproduccion", [
              ...insumosresumenproduccion,
              ...results,
            ]);
          } else {
            FormResumenProduccion.setFieldValue("insumosresumenproduccion", [
              ...insumosresumenproduccion,
              {
                ...values,
                ...values?.costo,
              },
            ]);
          }
        }
      }}
    >
      <FormUi
        name="FormResumenProduccion"
        form={form}
        initialValues={initialValues(activeResumenProduccion)}
        handleSubmit={handleSubmit}
        layout="vertical"
        autoComplete="off"
      >
        <ResumenProduccionForm handleCloseForm={handleCloseForm} />
      </FormUi>
    </Form.Provider>
  );
};
