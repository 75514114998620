import React, { useEffect } from "react";
import { PageHeaderUi } from "../../../../ui/PageHeaderUi";
import { ButtonUi } from "../../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../../types/typesConstants";
import { Col, Row, Space } from "antd";
import { ProgramacionTejidoTable } from "./ProgramacionTejidoTable";
import { useDispatch, useSelector } from "react-redux";
import {
  get_excel_programacionestejido_by_filters,
  programacionTejidoStartLoading,
  showFormProgramacionTejido,
} from "../../../../../actions/produccion";
import { ProgramacionTejidoScreen } from "./ProgramacionTejidoScreen";
import { ProgramacionTejidoSearchTable } from "./ProgramacionTejidoSearchTable";
import { FileExcelOutlined } from "@ant-design/icons";

const ProgramacionTejidoListScreen = () => {
  const dispatch = useDispatch();
  const { programacionesTejido, filterFormSearchProgramacionTejido, loading } =
    useSelector((state) => state.produccion);
  const handleNuevo = () => {
    dispatch(showFormProgramacionTejido());
  };
  const handleReporteExcel = () => {
    if (!!filterFormSearchProgramacionTejido)
      get_excel_programacionestejido_by_filters(
        filterFormSearchProgramacionTejido
      );
    else get_excel_programacionestejido_by_filters();
  };
  return (
    <PageHeaderUi
      title="Programación de Tejido"
      subTitle="Listado"
      extra={[
        <Space key="opts" wrap>
          <ButtonUi
            key="rptexcel"
            typeUi={TipoButtonConstants.CustomButton}
            onClick={handleReporteExcel}
            icon={<FileExcelOutlined />}
            text="Reporte"
            type="success"
          />
          <ButtonUi
            key="nuevo"
            typeUi={TipoButtonConstants.Nuevo}
            onClick={handleNuevo}
          />
        </Space>,
      ]}
    >
      <div style={{ overflow: "hidden" }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            <ProgramacionTejidoSearchTable />
          </Col>
        </Row>
        <Row>
          <Col sm={32} md={32} xl={24}>
            <ProgramacionTejidoTable
              data={programacionesTejido}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
    </PageHeaderUi>
  );
};

export const ProgramacionTejidoList = () => {
  const { visibleFormProgramacionTejido } = useSelector(
    (state) => state.produccion
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(programacionTejidoStartLoading());
  }, []);
  if (visibleFormProgramacionTejido) {
    return <ProgramacionTejidoScreen />;
  } else {
    return <ProgramacionTejidoListScreen />;
  }
};
export default ProgramacionTejidoList;
